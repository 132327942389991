/* 主轮播样式 */
.slider-for .slick-slide img {
    width: 100%;
    height: 400px;
    object-fit: cover;
  }
  
  /* 导航轮播样式 */
  .slider-nav {
    margin-top: 20px;
  }
  .slider-nav .slick-slide {
    padding: 0 10px;
    cursor: pointer;
  }
  .slider-nav .slick-slide img {
    width: 100px;
    height: 60px;
    opacity: 0.6;
    transition: opacity 0.3s;
  }
  .slider-nav .slick-current img {
    opacity: 1;
    border: 2px solid #1890ff;
  }
  